import React from 'react'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import theme from '../const/theme'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import { rhythm } from '../utils/typography'
import { PageWidthOverride } from '../const/styles'

const StyledSubtitle = styled.h5`
  font-family: ${theme.font.san_serif}, sans-serif;
  color: ${theme.colors.green};
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: ${rhythm(.3)};
`

const StyledTitle = styled.h1`
  margin-top: 0;
`

const ArticleWrapper = styled.div`
  padding: 0 1rem;
  @media (min-width: 1200px) {
    margin: 0 auto;
    padding: 0;
    width: ${theme.articleWidth};

    > p {
      .gatsby-resp-image-wrapper {
        margin-top: ${rhythm(1.5)};
      }
    }
  }
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <Image placeholderStyle={{filter: `blur(10px)`}} fluid={post.frontmatter.image.childImageSharp.fluid} />
        <ArticleWrapper>
          <StyledSubtitle>{post.frontmatter.subtitle}</StyledSubtitle>
          <StyledTitle>{post.frontmatter.title}</StyledTitle>
          <MDXRenderer>{post.body}</MDXRenderer>
          <ul
            className="pagination"
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              marginTop: `3rem`,
              padding: 0,
            }}
          >
            <li>
              {next && (
                <AniLink fade duration={0.5} to={next.fields.slug} rel="next">
                  <small>← {next.frontmatter.title}</small>
                </AniLink>
              )}
            </li>
            <li>
              {previous && (
                <AniLink fade duration={0.5} to={previous.fields.slug} rel="prev">
                  <small> {previous.frontmatter.title} →</small>
                </AniLink>
              )}
            </li>
          </ul>
        </ArticleWrapper>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1100, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`
